<template>
  <svg
    @click="onClick"
    v-long-press="1000"
    @long-press-start="onLongPressStart"
    @long-press-stop="onLongPressStop"
    @long-press-click="onLongPressClick"
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="tiny"
    version="1.2"
    :width="width"
    :height="height"
    viewBox="0 0 330 340"
  >
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      stroke="transparent"
      stroke-width="1"
      fill="transparent"
    />
    <rect
      :fill="ColorRectancle"
      transform="matrix(1 0 0 1 0 95)"
      width="110"
      height="130"
      stroke-width="3"
      stroke="#000000"
    />
    <ellipse
      :fill="ColorElipse"
      transform="matrix(1 0 0 1 110 160)"
      rx="19"
      ry="40"
      stroke-width="6"
      stroke="#FFFFFF"
    />
    <ellipse
      :fill="ColorElipse"
      transform="matrix(1 0 0 1 110 160)"
      rx="18"
      ry="39"
      stroke-width="2"
      stroke="#000000"
    />

    <g :visibility="VisibilitySpray" :fill="ColorSpray">
      <circle
        transform="matrix(1 0 0 1 150 160)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 180 130)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 180 160)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 180 190)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 210 100)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 210 130)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 210 160)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 210 190)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 210 220)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 240 70)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 240 100)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 240 130)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 240 160)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 240 190)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 240 220)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 240 250)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 40)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 70)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 100)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 130)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 160)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 190)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 220)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 250)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 270 280)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 10)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 40)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 70)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 100)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 130)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 160)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 190)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 220)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 250)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 280)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
      <circle
        transform="matrix(1 0 0 1 300 310)"
        r="8"
        stroke-width="2"
        stroke="#000000"
      />
    </g>
  </svg>
</template>


<script>

import LongPress from "../../../plugins/longPressEvent"

export default {
    name: "smarticonnozzle",

    directives: {
        'long-press': LongPress
    },
    props: {
    id: {
        type: Number,
        default: -1,
        required: true,
    },
    zone: {
        type: Number,
        default: -1,
        required: true,
    },
    width: {
        type: String,
        default: "100%",
        required: false,
    },
    height: {
        type: String,
        default: "100%",
        required: false,
    },
    color: {
        type: String,
        default: "#757575",
        required: false,
    },
    enabled: {
        type: Boolean,
        default: true,
        required: false,
    },
    sprayon: {
        type: Boolean,
        default: false,
        required: false,
    },
    sprayerror: {
        type: Boolean,
        default: false,
        required: false,
    },
    option: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      defOption: {
        clickable: true,
        itemStyle: {
          enabledcolor: "#757575",
          disabledcolor: "#ffffff",
          errorcolor: "#F44336",
          oncolor: "#66ff33",
          offcolor: "#ffffff",
        },
      },
    };
  },

  computed: {
    cmpOption() {
      var returnVal = this.defOption;

      if (Object.prototype.hasOwnProperty.call(this.option, "clickable"))
        returnVal.clickable = this.option.clickable;

      if (Object.prototype.hasOwnProperty.call(this.option, "itemStyle"))
        returnVal.size = this.cmpOptionItemStyle(this.option.itemStyle);

      return returnVal;
    },

    VisibilitySpray() {
      return this.enabled ? "visible" : "hidden";
    },

    ColorSpray() {
      if (this.sprayerror)
        return this.sprayon
          ? this.cmpOption.itemStyle.errorcolor
          : this.cmpOption.itemStyle.offcolor;

      return this.sprayon
        ? this.cmpOption.itemStyle.oncolor
        : this.cmpOption.itemStyle.offcolor;
    },

    ColorElipse() {
      if (this.sprayerror) return this.cmpOption.itemStyle.errorcolor;

      return this.sprayon ? this.cmpOption.itemStyle.oncolor : this.color;
    },

    ColorRectancle() {
      // if(!this.enabled)
      //     return this.sprayDisabledColor;
      return this.enabled ? this.color : this.cmpOption.itemStyle.disabledcolor;
    },
  },

  methods: {
    cmpOptionItemStyle(value) {
      var returnVal = this.defOption.itemStyle;
      if (Object.prototype.hasOwnProperty.call(value, "enabledcolor"))
        returnVal.enabledcolor = value.enabledcolor;
      if (Object.prototype.hasOwnProperty.call(value, "disabledcolor"))
        returnVal.disabledcolor = value.disabledcolor;
      if (Object.prototype.hasOwnProperty.call(value, "errorcolor"))
        returnVal.errorcolor = value.errorcolor;
      if (Object.prototype.hasOwnProperty.call(value, "oncolor"))
        returnVal.oncolor = value.oncolor;
      if (Object.prototype.hasOwnProperty.call(value, "offcolor"))
        returnVal.offcolor = value.offcolor;
      return returnVal;
    },

    onClick(event) {
        console.debug("onClick", event);
        var res = {
                name: this.$options.name,
                id: this.id,
                zone: this.zone,
                action: {
                    name: "click",
                },
        };
        this.$emit("click", res);
    },

    onLongPressStart () {
        console.debug("onLongPressStart")
        var res = {
                name: this.$options.name,
                id: this.id,
                zone: this.zone,
                action: {
                    name: "click",
                },
        };
        this.$emit("long-press-start", res);
    
    },
    onLongPressStop () {
        console.debug("onLongPressStop");
        var res = {
                name: this.$options.name,
                id: this.id,
                zone: this.zone,
                action: {
                    name: "click",
                },
        };
        this.$emit("long-press-stop", res);
    },
    onLongPressClick (event) {
        console.debug("onLongPressClick", event);
        var res = {
                name: this.$options.name,
                id: this.id,
                zone: this.zone,
                action: {
                    name: "click",
                },
        };
        this.$emit("long-press-click", res);
    },
},



  mounted() {
    //console.log('Updated  SprayDefault')
    //console.debug("child mounted", this.id);
    this.$emit("childmounted", this.id);
  },
};
</script>
