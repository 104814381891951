<template lang="html">
    <v-card
        elevation="10"
        height="100%"
    >      
        <v-toolbar
            ligth
            cards                
            flat
            color="green lighten-4"
        >
            <v-card-title class="text-h6 font-weight-regular">
                <v-icon class="mr-2" large>mdi-spray</v-icon>
                Düsen Rechner 
            </v-card-title>
            <v-spacer> </v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        fab
                        v-bind="attrs"
                        v-on="on"
                        class="mt-6 mx-5"
                        @click="onOpenSettings"
                    >
                        <v-icon dark>
                            mdi-cog-outline
                        </v-icon>
                    </v-btn>
                    
                </template>
                <span>Konfiguration</span>                           
            </v-tooltip>                
            
        </v-toolbar> 
 
        <v-dialog v-model="dialogNozzleCfg.isVisible" width="800">
            <nozzleCfg :data="dialogNozzleCfg.data" :option="dialogNozzleCfg.option" :isVisible="dialogNozzleCfg.isVisible" @close="dialogNozzleCfg.isVisible = false" @onclicksave="onclicksaveNozzle"></nozzleCfg>
        </v-dialog> 
        
        <v-container class="ma-0" > 
            <v-row class="ml-6" v-if="!showZones">
                <v-switch
                    v-model="switchZone"
                    label="Rechner/Düsen"
                    >
                </v-switch>
            </v-row>         
            <v-layout>

                <section v-if="wrkData.nozzles.zones.length > 4  &&  (showZones || switchZone)"  class="my-2 ml-2" style="min-width: 20px">
                    <v-card
                    height="100%"
                    color="green lighten-5">
                    </v-card>
                
                </section>
                

                <section  v-if="wrkData.nozzles.zones.length > 4  && (showZones || switchZone)" class="my-2 ml-2"  style="min-width: 40px">
                    <v-row class="pa-4 " v-for="(item, index) in wrkData.nozzles.zones[4].slice().reverse()" :key="index" >
                        <nozzle
                            :id="item.idx"
                            :zone="item.zone"
                            :color="item.hex"
                            :sprayon = "true"
                            :enabled="item.enabled == 1"
                            transform="scale(-1,1) translate(0, 0)"
                            :height="nozzleHeigth"
                            :clickable="true"
                            @long-press-start="onLongPressStart"
                            @long-press-click="onLongPressClick"
                        ></nozzle>
                    </v-row>
                </section>

                <section v-if="wrkData.nozzles.zones.length > 2  &&  (showZones || switchZone)"  class="my-2 ml-2"  style="min-width: 40px">
                    <v-row class="pa-4 " v-for="(item, index) in wrkData.nozzles.zones[2].slice().reverse()" :key="index" >
                        <nozzle
                            :id="item.idx"
                            :zone="item.zone"
                            :color="item.hex"
                            :sprayon = "true"
                            :enabled="item.enabled == 1"
                            transform="scale(1,1) translate(0, 0)"
                            :height="nozzleHeigth"
                            :clickable="true"
                                @long-press-start="onLongPressStart"
                                @long-press-click="onLongPressClick"
                        ></nozzle>
                    </v-row>
                </section>

                <section v-if="wrkData.nozzles.zones.length > 2  &&  (showZones || switchZone)"  class="my-2 ml-2" style="min-width: 20px">
                    <v-card
                    class="fill-height"
                    color="green lighten-5">
                    </v-card>
                
                </section>

                <section  v-if="wrkData.nozzles.zones.length > 0  &&  (showZones || switchZone)" class="my-2 ml-2"  style="min-width: 40px">
                    <v-row class="pa-4 " v-for="(item, index) in wrkData.nozzles.zones[0].slice().reverse()" :key="index" >
                        <nozzle
                            :id="item.idx"
                            :zone="item.zone"
                            :color="item.hex"
                            :sprayon = "true"
                            :enabled="item.enabled == 1"
                            transform="scale(-1,1) translate(0, 0)"
                            :height="nozzleHeigth"
                            :clickable="true"
                                @long-press-start="onLongPressStart"
                                @long-press-click="onLongPressClick"
                        ></nozzle>
                    </v-row>
                </section>
                <section v-if="!showZones && switchZone"  class="my-2 ml-2" style="width: 60vh; min-width: 60px">
                    <v-card
                    class="fill-height mx-3 pa-3 "
                    color="grey lighten-5">
                    </v-card>
                
                </section>

                <section v-if="showZones || !switchZone" class="my-2" style="width: 100vh;"> 
                    <v-card
                    class="fill-height mx-3 pa-3 "
                    color="grey lighten-5"
                    ref="content"                     
                    >  
                        <v-row height="500px"  align="center">
                            <v-col cols="12" sm="12"  tile >
                                  <gauge
                                    ref="gaugeactval"
                                    height="230px"
                                    :option="optionGauge"
                                    :setpointvalue="Number(gaugeVal)"
                                    :value="gaugeVal"
                                ></gauge>
                            </v-col>

                        </v-row>                         
                        
                        <v-row class="mt-n8" align="center">
                           <v-col cols="2" tile >
                                <!-- <v-btn
                                    class="mx-2"
                                    fab                                        
                                    small
                                    @click="onLockChange('rowDistance')"
                                >
                                    <v-icon v-if="!wrkData.values.rowDistance.locked" color="primary">
                                        mdi-lock-open
                                    </v-icon>
                                    <v-icon v-if="wrkData.values.rowDistance.locked" dark>
                                        mdi-lock
                                    </v-icon>                                        
                                </v-btn> -->
                            </v-col>
                             <v-col cols="10" tile >
                                <v-subheader>{{wrkData.values.rowDistance.caption}}</v-subheader>
                                <v-slider
                                v-model="wrkData.values.rowDistance.value"
                                :disabled="wrkData.values.rowDistance.locked"
                                :step="wrkData.values.rowDistance.step"
                                :max="wrkData.values.rowDistance.max"
                                :min="wrkData.values.rowDistance.min"
                                class="align-center mt-n6"
                                @change="onValueChanged('rowDistance')"
                                >
                                    <template v-slot:append>
                                        <v-text-field
                                        v-model="wrkData.values.rowDistance.value"
                                        class="mt-0 pt-0"
                                        :max="wrkData.values.rowDistance.max"
                                        :min="wrkData.values.rowDistance.min"
                                        :step="wrkData.values.rowDistance.step"
                                        type="number"
                                        style="width: 100px"
                                        :suffix='wrkData.values.rowDistance.unit'
                                        @change="onValueChanged('rowDistance')"
                                        >
                                        </v-text-field>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>                         
                        <v-row class="mt-n6"  align="center">
                           <v-col cols="2" tile >
                                <!-- <v-btn
                                    class="mx-2"
                                    fab                                        
                                    small
                                    @click="onLockChange('rowGap')"
                                >
                                    <v-icon v-if="!wrkData.values.rowGap.locked" color="primary">
                                        mdi-lock-open
                                    </v-icon>
                                    <v-icon v-if="wrkData.values.rowGap.locked" dark>
                                        mdi-lock
                                    </v-icon>                                        
                                </v-btn> -->
                            </v-col>
                             <v-col cols="10" tile >
                                <v-subheader>{{captionRowGap}}</v-subheader>
                                <v-slider
                                    v-model="wrkData.values.rowGap.value"
                                    :disabled="wrkData.values.rowGap.locked"
                                    :step="wrkData.values.rowGap.step"
                                    :max="wrkData.values.rowGap.max"
                                    :min="wrkData.values.rowGap.min"
                                    class="align-center mt-n6"
                                    @change="onValueChanged('rowGap')"
                                >
                                    <template v-slot:append>
                                        <v-text-field
                                        v-model="wrkData.values.rowGap.value"
                                        class="mt-0 pt-0"
                                        :max="wrkData.values.rowGap.max"
                                        :min="wrkData.values.rowGap.min"
                                        :step="wrkData.values.rowGap.step"
                                        type="number"
                                        style="width: 100px"
                                        :suffix='wrkData.values.rowGap.unit'
                                        @change="onValueChanged('rowGap')"
                                        >
                                        </v-text-field>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row> 
                        <v-row class="mt-n6" align="center">
                            <v-col cols="2" tile >
                                <v-btn
                                    class="mx-2"
                                    fab                                        
                                    small
                                    @click="onLockChange('flow')"
                                >
                                    <v-icon v-if="!wrkData.values.flow.locked" color="primary">
                                        mdi-lock-open
                                    </v-icon>
                                    <v-icon v-if="wrkData.values.flow.locked" dark>
                                        mdi-lock
                                    </v-icon>                                        
                                </v-btn>
                            </v-col>
                            <v-col cols="10" tile >
                                <v-subheader>{{wrkData.values.flow.caption}}</v-subheader>
                                <v-slider
                                v-model="wrkData.values.flow.value"
                                :disabled="wrkData.values.flow.locked"
                                :step="wrkData.values.flow.step"
                                :max="wrkData.values.flow.max"
                                :min="wrkData.values.flow.min"
                                class="align-center mt-n6"
                                @change="onValueChanged('flow')"
                                >
                                    <template v-slot:append>
                                        <v-text-field
                                        v-model="wrkData.values.flow.value"
                                        class="mt-0 pt-0"
                                        :max="wrkData.values.flow.max"
                                        :min="wrkData.values.flow.min"
                                        :step="wrkData.values.flow.step"
                                        type="number"
                                        style="width: 100px"
                                        :suffix='wrkData.values.flow.unit'
                                        @change="onValueChanged('flow')"
                                        >
                                        </v-text-field>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>     
                        <v-row class="mt-n6" align="center">
                           <v-col cols="2" tile >
                                <v-btn
                                    class="mx-2"
                                    fab                                        
                                    small
                                    @click="onLockChange('speed')"
                                >
                                    <v-icon v-if="!wrkData.values.speed.locked" color="primary">
                                        mdi-lock-open
                                    </v-icon>
                                    <v-icon v-if="wrkData.values.speed.locked" dark>
                                        mdi-lock
                                    </v-icon>                                        
                                </v-btn>
                            </v-col>
                             <v-col cols="10" tile>
                                <v-subheader>{{wrkData.values.speed.caption}}</v-subheader>
                                <v-slider
                                    v-model="wrkData.values.speed.value"
                                    :disabled="wrkData.values.speed.locked"
                                    :step="wrkData.values.speed.step"
                                    :max="wrkData.values.speed.max"
                                    :min="wrkData.values.speed.min"
                                    class="align-center mt-n6"
                                    @change="onValueChanged('speed')"
                                    
                                >
                                    <template v-slot:append>
                                        <v-text-field
                                        v-model="wrkData.values.speed.value"
                                        class="mt-0 pt-0"
                                        :max="wrkData.values.speed.max"
                                        :min="wrkData.values.speed.min"
                                        :step="wrkData.values.speed.step"
                                        type="number"
                                        style="width: 100px"
                                        :suffix='wrkData.values.speed.unit'
                                        @change="onValueChanged('speed')"
                                        >
                                        </v-text-field>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n6" align="center">
                           <v-col cols="2" tile >
                                <v-btn
                                    class="mx-2"
                                    fab                                        
                                    small
                                    @click="onLockChange('pressure')"
                                >
                                    <v-icon v-if="!wrkData.values.pressure.locked" color="primary">
                                        mdi-lock-open
                                    </v-icon>
                                    <v-icon v-if="wrkData.values.pressure.locked" dark>
                                        mdi-lock
                                    </v-icon>                                        
                                </v-btn>
                            </v-col>
                             <v-col cols="10" tile>
                                <v-subheader>{{wrkData.values.pressure.caption}}</v-subheader>
                                <v-slider
                                    v-model="wrkData.values.pressure.value"
                                    :disabled="wrkData.values.pressure.locked"
                                    :step="wrkData.values.pressure.step"
                                    :max="wrkData.values.pressure.max"
                                    :min="wrkData.values.pressure.min"
                                    class="align-center mt-n6"
                                    @change="onValueChanged('pressure')"
                                    
                                >
                                    <template v-slot:append>
                                        <v-text-field
                                        v-model="wrkData.values.pressure.value"
                                        class="mt-0 pt-0"
                                        :max="wrkData.values.pressure.max"
                                        :min="wrkData.values.pressure.min"
                                        :step="wrkData.values.pressure.step"
                                        type="number"
                                        style="width: 100px"
                                        :suffix='wrkData.values.pressure.unit'
                                        @change="onValueChanged('pressure')"
                                        >
                                        </v-text-field>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                    </v-card>
                </section>

                <section v-if="wrkData.nozzles.zones.length > 1  &&  (showZones || switchZone)" class="my-2 ml-2" style="min-width: 40px">
                    
                    <v-row class="pa-4" v-for="(item, index) in wrkData.nozzles.zones[1].slice().reverse()" :key="index" >
                        <nozzle
                            :id="item.idx"
                            :zone="item.zone"
                            :color="item.hex"
                            :sprayon = "true"
                            :enabled="item.enabled == 1"
                            transform="scale(1,1) translate(0, 0)"
                            :height="nozzleHeigth"
                            :clickable="true"  
                            @long-press-start="onLongPressStart"
                            @long-press-click="onLongPressClick" 

                        ></nozzle>
                    </v-row>

                </section>


                <section v-if="wrkData.nozzles.zones.length > 3  &&  (showZones || switchZone)"  class="my-2 ml-2" style="min-width: 20px">
                    <v-card
                    class="fill-height"
                    color="green lighten-5">
                    </v-card>
                
                </section>

                <section v-if="wrkData.nozzles.zones.length > 3  &&  (showZones || switchZone)"  class="my-2 ml-2" style="min-width: 40px">                    
                    <v-row class="pa-4" v-for="(item, index) in wrkData.nozzles.zones[3].slice().reverse()" :key="index" >
                        <nozzle
                            :id="item.idx"
                            :zone="item.zone"
                            :color="item.hex"
                            :sprayon = "true"
                            :enabled="item.enabled == 1"
                            transform="scale(-1,1) translate(0, 0)"
                            :height="nozzleHeigth"
                            :clickable="true"  
                            @long-press-start="onLongPressStart"
                            @long-press-click="onLongPressClick" 
                        ></nozzle>
                    </v-row>
                </section>
                <section v-if="wrkData.nozzles.zones.length > 5  &&  (showZones || switchZone)"  class="my-2 ml-2" style="min-width: 40px">                    
                    <v-row class="pa-4" v-for="(item, index) in wrkData.nozzles.zones[5].slice().reverse()" :key="index" >
                        <nozzle
                            :id="item.idx"
                            :zone="item.zone"
                            :color="item.hex"
                            :sprayon = "true"
                            :enabled="item.enabled == 1"
                            transform="scale(1,1) translate(0, 0)"
                            :height="nozzleHeigth"
                            :clickable="true"  
                            @long-press-start="onLongPressStart"
                            @long-press-click="onLongPressClick" 
                        ></nozzle>
                    </v-row>
                </section>
                <section v-if="wrkData.nozzles.zones.length > 5  &&  (showZones || switchZone)"  class="my-2 ml-2" style="min-width: 20px">
                    <v-card
                    class="fill-height"
                    color="green lighten-5">
                    </v-card>
                
                </section>


            </v-layout>
        </v-container>
    </v-card>
</template>



<script>
import LongPress from "../../plugins/longPressEvent"
import Nozzle from "../../components/VueControls/smarticons/Nozzle.vue";
import GAUGE from "../../components/VueControls/gauges/SetActGauge.vue";

// import AxiosService from "../../services/axios.service";
const math = require("../../utils/math")

export default{

name: "DialogSprayerAssistant",
category: "sprayer",

directives: {
    'long-press': LongPress
},


components: {
    nozzleCfg:()=> import('../../components/ctrl/InputMasks/nozzleConfig.vue'),
    nozzle: Nozzle,
    gauge: GAUGE,
},

data: function () {
    return {
        switchZone:false,
        defaultNozzle:{
                zone:1,
                enabled:true,
                nozzle_id:0,
                idx:0,                            
                color_id: 0, 
                hex: "#FFFFFF", 
                name: "n/a",
                size:"",
                table_type_id: null
            },            
        wrkData:{
            config:{
                nozzle_count_per_zone:2
            },
        
            nozzles:{
                zones:[
                    [
                        {
                            zone:1,
                            enabled:true,
                            nozzle_id:0,
                            idx:0,                            
                            color_id: 0, 
                            hex: "#FFFFFF", 
                            name: "n/a",
                            size:"",
                            table_type_id: null
                        }
                    ],
                    [
                        {
                      
                            zone:2,
                            enabled:true,
                            nozzle_id:0,
                            idx:0,                       
                            color_id: 0, 
                            hex: "#FFFFFF", 
                            name: "n/a",
                            size:"",
                            table_type_id: null  
                        
                        }
                    ]  
                ]
            },
            
            status:{
                zones:[{active: false}, {active: false},{active: false},{active: false}]
            },
            setValues:{        
                speed:0,
                pressure:0,
                outputperarea:0,
                level:0,
                flow:0,
            },

            values: {
                flow:{
                    max: 500,
                    min: 0,
                    value: 0,
                    caption:'Ausstoß',
                    unit:'l/ha',
                    step: 1, 
                    locked: true 
                },                 
                rowDistance:{
                    max: 4,
                    min: 1,
                    value: 1.8,
                    caption:'Gassenbreite',
                    unit:'m',
                    step: 0.1, 
                    locked: true 
                }, 
                rowGap:{
                    max: 4,
                    min: 1,
                    value: 1,
                    caption:'Gasse',
                    unit:'',
                    step: 1, 
                    locked: true 
                },
                speed:{
                    max: 20,
                    min:5,
                    value:1,
                    caption:'Geschwindigkeit',
                    unit:'km/h',
                    step: 0.1, 
                    locked: true
                },
                pressure:{
                    max: 20,
                    min:1,
                    value:1,
                    caption:'Druck',
                    unit:'bar',
                    step: 0.1,
                    locked: false
                },                
            }

        },

 
        gaugeVal:0,
        optionGauge:{
                startAngle: 0,
                endAngle: 180,
                radius: 150,
                min: 0,
                max: 4000,
                unit: "l/ha",
                size: {
                    offsetViewBoxWidth: 0,
                    offsetViewBoxHeigth: -150,                                   
                },
                itemStyle: {
                    backcolor: "#BDBDBD",
                    setcolor: "#4CAF50",
                    actcolor: "#616161",
                    stroke: 60,
                    setStrokePercent: 20,
                },    
        },

        Axis1:{
            xAxis:[],
            yAxis:[],
        },
        Axis2:{
            xAxis:[10,20,30],
            yAxis:[200,1000,4000],
        },

        chartData: [
                    [0, 0],
                    [30,500],
                    
                    ],

        chartData2: [
                    [0, 0],
                    [10,200],
                    
                    ],                    

        contentWindow: {
            width: 0,
            height: 2000,
        },

        dialogNozzleCfg:{
            isVisible: false,
            data: {},
            option:{}
        },

    }
},

computed: {

    user(){
            return this.$store.state.auth.user
    },

    access(){
        return this.$store.state.app.access
    },   

    company() {
        return this.$store.state.app.actCompany.meta
    },


    tableTypes(){
        console.debug("tableTypes", this.$store.state.app.nozzles.table_types)
        return this.$store.state.app.nozzles.table_types
    },

    nozzleHeigth() {
        // console.debug("nozzleHeigth", this.contentWindow.height);
        //
        // const tmp = (this.contentWindow.height - this.wrkData.nozzles.zones[0].length * 32) / this.wrkData.nozzles.zones[0].length;
        const height = this.contentWindow.height
        const tmp = (height - this.wrkData.nozzles.zones[0].length * 32) / this.wrkData.nozzles.zones[0].length;
        return tmp + "";
    },

    captionRowGap(){
        return this.wrkData.values.rowGap.value == 1 ? "Jede Gasse" : "Jede " + this.wrkData.values.rowGap.value + ". Gasse"
    },

    showZones(){
        
        let res = true
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': 
                res = false
                break;
          case 'sm':
                res = false
                break;
        //   case 'md': res = false
        //   case 'lg': res = false
        //   case 'xl': res = false
        }
        console.debug("showZones", res)
        return res
    },

    chartOption(){
        const colors = ['#5470C6', '#EE6666'];
        return {
                color: colors,
                tooltip: {
                    trigger: 'none',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                legend: {},
                 grid:{
                    left:"15%",
                    right:"15%"
                },   
                xAxis: [
                    {
                        name: 'bar',
                        type: 'category', 
                        showSymbol: false,
                        // axisTick: {
                        //     alignWithLabel: true
                        // },
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                            color: colors[0]
                            }
                        },
                        
                        data: this.Axis1.xAxis
                    },
                    {
                        
                        name: 'km/h',
                        type: 'category', 
                        showSymbol: false,
                        // axisTick: {
                        //     alignWithLabel: true
                        // },
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: colors[1]
                            }
                        },
                        
                        data: this.Axis2.xAxis
                    }
                ],
                yAxis: [
                    {
                    type: 'value'
                    }
                ],
                series: [
                    {
                        name: 'bar',
                        type: 'line',
                        xAxisIndex: 0,
                        showSymbol: false,
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.Axis1.yAxis,
                        markLine: {
                            
                                symbol: ['none', 'none'],
                                data: [
                                    {
                                        name: 'Horizontal line with Y value at 100',
                                        yAxis: this.wrkData.values.flow.value
                                    },
                                ]
                            
                        }

                    },
                    {
                        name: 'km/h',
                        type: 'line',
                        showSymbol: false,
                        xAxisIndex: 1,
                        smooth: true,
                        // emphasis: {
                        //     focus: 'series'
                        // },
                        data: this.Axis2.yAxis
                    }
                ]


        }
    }    
                         


},

methods: {

    onClose(){
        console.debug('onClose')
        this.$emit('close')  
        //this.$emit('update:isVisible', false)        
    },

    onLongPressStart (data) {
        console.debug("onLongPressStart", data)
        // const wrkDataNozzle = this.wrkData.nozzles.zones[data.zone-1][data.id]
        const wrkDataNozzle = this.wrkData.nozzles.zones[data.zone-1][data.id]
        const res = {
            actNozzle: wrkDataNozzle,
        }
        console.debug("onClickNozzelConfig", res);

        this.dialogNozzleCfg.option = {
            isBaseConfig: false,
            hasSingelAdjustmentBtn: false,
            hasZoneAdjustment: false,
            zoneCount:2,
            nozzleCount:6,

        }
        this.dialogNozzleCfg.data = res
        this.dialogNozzleCfg.isVisible = true;    
  
    },


    onLongPressClick (data) {
        console.debug("onLongPressClick", data)
        // this.onLongPressStart(data)
        this.wrkData.nozzles.zones[data.zone-1][data.id].enabled = this.wrkData.nozzles.zones[data.zone-1][data.id].enabled ? 0 : 1
        this.calcAllNozzles()
        this.calculation()
        localStorage.setItem('plantProtectionCalcNozzles', JSON.stringify(this.wrkData.nozzles))

    },

    onOpenSettings () {
        console.debug("onOpenSettings")
        // const wrkDataNozzle = this.wrkData.nozzles.zones[data.zone-1][data.id]
        this.dialogNozzleCfg.option = {
            isBaseConfig: true,
            hasSingelAdjustmentBtn: false,
            hasZoneAdjustment: true,
            zoneCount:this.wrkData.nozzles.zones.length,
            nozzleCount:this.wrkData.nozzles.zones[0].length,
        }
        this.dialogNozzleCfg.data = {
            actNozzle: this.wrkData.nozzles.zones[0][0]
        }
        this.dialogNozzleCfg.isVisible = true;    
  
    },    

    onLockChange(data){
        console.debug("onLockChange", data)
        this.gaugeVal = 0
        this.optionGauge.unit = this.wrkData.values[data].unit
        this.optionGauge.max = this.wrkData.values[data].max

        this.wrkData.values.rowDistance.locked = false
        this.wrkData.values.rowGap.locked = false
        this.wrkData.values.speed.locked = data == "speed"
        this.wrkData.values.pressure.locked = data == "pressure"
        this.wrkData.values.flow.locked = data == "flow"
        this.calculation()
    },
    
    onValueChanged(id){
        console.debug('onValueChanged',id)
        this.calcAllNozzles()
        this.calculation()
        localStorage.setItem('plantProtectionCalcValues', JSON.stringify(this.wrkData.values))

    },

    onclicksaveNozzle(data){
        console.debug('onclicksaveNozzle', data)
        let payload = data 
        payload.action = "changenozzle"   
        
        if(data.nozzleallsame || data.isBaseConfig){

            if(data.zoneCount != this.wrkData.nozzles.zones.length){
                this.wrkData.nozzles.zones = []
                for (let i = 0; i < data.zoneCount; i++) {
                    this.wrkData.nozzles.zones.push([]);                    
                }
            }
 
            for (const idx in this.wrkData.nozzles.zones) {
                let zone = this.wrkData.nozzles.zones[idx]
                const diff = data.enabledcount - zone.length
                if(diff > 0){
                    this.addNozzles2Zone(zone, diff, idx, data.nozzle)
                }
                else if(diff < 0){
                    zone = JSON.parse(JSON.stringify(zone.slice(0,diff)))
                }   
                
                for (const idx in zone) {
                    delete data.nozzle.nozzle_id
                    delete data.nozzle.idx
                    delete data.nozzle.zone
                    zone[idx] = Object.assign(zone[idx],data.nozzle)
                } 
                
                this.wrkData.nozzles.zones[idx] = zone
            }
 
        }
        else{
            this.wrkData.nozzles.zones[data.nozzle.zone-1][data.nozzle.idx] = data.nozzle
        }
        this.calcAllNozzles()
        this.createChart()
        this.calculation()
        localStorage.setItem('plantProtectionCalcNozzles', JSON.stringify(this.wrkData.nozzles))

        
        
        // this.sendToSocket(payload) 
    },

    addNozzles2Zone(zone, count, zoneidx, nozzle){
        console.debug('addNozzles2Zone', zone, count)
        for (let index = 0; index < count; index++) {
            const idx = zone.length
            zone.push( JSON.parse(JSON.stringify(nozzle)))
            zone[idx].idx = idx
            zone[idx].id = idx
            zone[idx].zone = Number(zoneidx) + 1
            
        }
        
    },

    calcAllNozzles(){
        console.debug('calcAllNozzles')
        const zone = this.wrkData.nozzles.zones
        let ptPressure = []
        let ptSpeed = []
        this.Axis1.xAxis = []
        for (let index = this.wrkData.values.pressure.min; index <= this.wrkData.values.pressure.max; index++) {
            this.Axis1.xAxis.push(index)
            ptPressure.push([index, 0])            
        }
        this.Axis2.xAxis = []
        for (let index = this.wrkData.values.speed.min; index <= this.wrkData.values.speed.max; index++) {
            this.Axis2.xAxis.push(index)
            ptSpeed.push([index, 0])  
        }

        for(const i in zone){ 
            for(const item of zone[i]){
                if(item.enabled == 1){
                    if(!item.reg || !item.funcGetY)
                        return 

                    for (const point of ptPressure) {
                        point[1] += item.funcGetY(point[0])  
                    }
                    
                }
            }
            
        }
        const reg = math.power(ptPressure, {precision:3})
        this.Axis1.yAxis = []
        for (const point of ptPressure) {
            point[1] = ( (point[1] * 600)/(this.wrkData.values.speed.value *  (this.wrkData.values.rowDistance.value))) / this.wrkData.values.rowGap.value 
            this.Axis1.yAxis.push(point[1])
        }
        
        this.Axis2.yAxis = []
        for (const pt of ptSpeed) {
            const flow = reg.funcGetY(this.wrkData.values.pressure.value)
            pt[1] = ( (reg.funcGetY(flow) * 600)/(pt[0] *  (this.wrkData.values.rowDistance.value))) / this.wrkData.values.rowGap.value 
            this.Axis2.yAxis.push(pt[1])
        }        
        // this.chartData = ptPressure
    },

    createChart(){
        console.debug('createChart')
        if(this.wrkData.values.flow.locked){
            this.createChartFlow()
        }       
        else if(this.wrkData.values.pressure.locked){
            this.createChartPressure()
        } 
    },

    createChartFlow(){
        console.debug('createChartFlow')
    },

    createChartPressure(){
        console.debug('createChartPressure')
        // for (let index = this.wrkData.values.min; index < this.wrkData.values.max; index++) {
            
            
        // }

    },


    calculation(){
        let data = null
        if(this.wrkData.values.flow.locked){
            this.calcFlow()
            data = this.wrkData.values.flow
        }
        else if(this.wrkData.values.pressure.locked){
            this.calcPressure()
            data = this.wrkData.values.pressure
        }  
        else if(this.wrkData.values.speed.locked){
            this.calcSpeed()
            data = this.wrkData.values.speed
        }       

        // if(this.wrkData.values.flow.max > this.gaugeVal){
        //         this.optionGauge.max = data.max
        //         return
        // }
            
        this.optionGauge.max = data.max * Math.ceil(this.gaugeVal/data.max) 

        
    },


    calcFlow(){
        console.debug('calcFlow', this.wrkData.nozzles)
        const zone = this.wrkData.nozzles.zones
    
        let flow = 0
        let flows = []
        for(let i in zone){  
            
            for(let item of zone[i]){  
                            
                if(item.enabled == 1){
                    if(!item.reg || !item.funcGetY)
                        return 

                    const tmp = item.funcGetY(this.wrkData.values.pressure.value) 
                    console.log("wrkData getFlow1", this.wrkData.values.pressure.value )
                    flows.push({zone:item.zone, idx:item.idx, lperMin:tmp})
                    flow += tmp

                }
            }
            
        }
        // console.log("wrkData getFlow", flow )
        let lperHa = -1

        if(this.wrkData.values.rowDistance.value && this.wrkData.values.speed.value )
        lperHa = ( (flow * 600)/(this.wrkData.values.speed.value *  (this.wrkData.values.rowDistance.value))) / this.wrkData.values.rowGap.value
        const test = (lperHa * this.wrkData.values.speed.value * this.wrkData.values.rowDistance.value *this.wrkData.values.rowGap.value)/600
       console.log("flow=(" + lperHa +" *" + this.wrkData.values.speed.value +" *" + this.wrkData.values.rowDistance.value +" *" + this.wrkData.values.rowGap.value +")/600")
         // this.wrkData.ActValues.flow.lperHour = Math.round(flow*60)
        console.log("lperMin", flow )
        console.log("lperHa", lperHa )
        console.log("test", test )
        if(this.wrkData.values.flow.locked){
            this.wrkData.values.flow.value = Math.round(lperHa)
            this.gaugeVal = Math.round(lperHa)
        }
        return Math.round(lperHa)
        

    },

    calcPressure(){
        console.debug('calcPressure', this.wrkData.nozzles)
        const zones = this.wrkData.nozzles.zones
    
        
        let reg = []
        let tmp = undefined
        let nozzleDifferent = false
        // let a =0
        // let b = 0
        for(let zone of zones){ 
            for(let item of zone){          
                if(item.enabled == 1){
                    if(tmp){
                        if(tmp != item.expression)
                            nozzleDifferent = true
                    }
                    tmp = item.expression
                    reg.push(item)                    
                }
            }
            
        }
        // console.log("wrkData getPress", reg )
        if(reg){
            let flowGes = (this.wrkData.values.flow.value * this.wrkData.values.speed.value * this.wrkData.values.rowDistance.value * this.wrkData.values.rowGap.value)/600
            if(!nozzleDifferent){
                let flow = flowGes/reg.length
                let press = reg[0].funcGetX(flow) //+Math.exp(Math.log(flowGes/a[1])/b[1])
                // console.log("wrkData getPress", press )
                if(this.wrkData.values.pressure.locked)
                    this.wrkData.values.pressure.value =  math.round(press, 1)
                    this.gaugeVal = this.wrkData.values.pressure.value
            }

        }
        
 
    },   


    calcSpeed(){
        console.debug('calcSpeed', this.wrkData.nozzles)
        const zone = this.wrkData.nozzles.zones
        
        let flow = 0
        let flows = []
        for(let i in zone){  
            
            for(let item of zone[i]){  
                            
                if(item.enabled == 1){
                    if(!item.reg || !item.funcGetY)
                        return 
                    const tmp = item.funcGetY(this.wrkData.values.pressure.value) 
                    console.log("wrkData getFlow1", this.wrkData.values.pressure.value )
                    flows.push({zone:item.zone, idx:item.idx, lperMin:tmp})
                    flow += tmp

                }
            }
            
        }
        // console.log("wrkData getFlow", flow )
        let speed = -1
    

        if(this.wrkData.values.rowDistance.value && this.wrkData.values.speed.value )
        speed = ((flow * 600)/(this.wrkData.values.flow.value *  this.wrkData.values.rowDistance.value)) / this.wrkData.values.rowGap.value
        const test = ((flow * 600)/(this.wrkData.values.flow.value *  this.wrkData.values.rowDistance.value)) / this.wrkData.values.rowGap.value
        console.log("speed", test )
        if(this.wrkData.values.speed.locked){
            this.wrkData.values.speed.value = Math.round(speed)
            this.gaugeVal = Math.round(speed)
        }
        return Math.round(speed)
 
    },       
    refreshContent(){
        console.debug('refreshContent');
        const comp = this.company
        this.isLoading = true
        this.$store.dispatch("app/getApiSetStore", {path:"api/company/nozzles/table_types", param:{store:"nozzles/table_types", id:comp.id, content:"plantprotection"}})
        .then((res)=>{ 
            console.debug("refreshContent", res)
            // this.ctrlIMPC.data.tableTypes = res.data
            // this.selectTableTypes = 0
            // this.changeTableTypes(1)
            // this.isLoading = false
            return this.$store.dispatch("app/getApiSetStore", {path:"api/company/nozzles/colors", param:{store:"nozzles/available_colors", id:-1, content:"plantprotection"}})

        }).then(()=>{ 

            const values = JSON.parse(localStorage.getItem('plantProtectionCalcValues'))
            if(values)
                this.wrkData.values = values

            const nozzles = JSON.parse(localStorage.getItem('plantProtectionCalcNozzles'))
            if(nozzles){
                this.wrkData.nozzles = nozzles
                for (const zone of this.wrkData.nozzles.zones) {
                    for (const item of zone) {
                        let funcGetY = null
                        let funcGetX = null
                        eval(item.reg.funcGetY)
                        item.funcGetY = funcGetY                                     
                        eval(item.reg.funcGetX)
                        item.funcGetX = funcGetX                                     
                    }
                }

                this.calcAllNozzles()
                this.createChart()
                this.calculation()
            }

            this.isLoading = false
        
        }).catch((error)=>{
            console.error(error);                
            this.isLoading = false
        })
        
    },    

    handleResize() {
        console.debug("handleResize", window)
        this.contentWindow.width = window.innerWidth
        this.contentWindow.height = window.innerHeight - 200
    },        


},

mounted() {
  console.debug("mounted" ,this.data)
  this.handleResize()
  this.onLockChange("flow")
  this.refreshContent()
},
created() {
    console.debug("created")
    window.addEventListener("resize", this.handleResize)
},
destroyed() {
    console.debug("destroyed")
    window.removeEventListener("resize", this.handleResize)
},

watch: { 

}        

};
</script>
<!-- 
<style>

.row-full{
 width: 100vw;
 position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.split {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}


.right {
    flex: 1;
    overflow-y: scroll; /* it works! */
}
.right::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
 -->
