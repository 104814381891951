<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="tiny"
    version="1.2"
    :height="height"
    :width="width"
    :view-box.camel="viewbox"
  >
    <text
      :fill="cmpOption.text.unittextcolor"
      :font-size="20"
      text-anchor="middle"
    >
      <tspan
        :fill="cmpOption.text.textcolor"
        :x="textPosXYfunction.x"
        :y="textPosXYfunction.y"
        :font-size="cmpOption.text.fontsize"
      >
        {{ value }}
      </tspan>
      {{ cmpOption.unit }}
    </text>
    <path
      id="outlineArc"
      fill="none"
      :stroke="cmpOption.itemStyle.backcolor"
      :stroke-width="cmpOption.itemStyle.stroke"
      :d="outlineArc.d"
    />
    <path
      id="setPointArc"
      fill="none"
      :stroke="cmpOption.itemStyle.setcolor"
      :stroke-width="setPointStroke"
      :d="setPointArc.d"
    />
    <path
      id="valueArc"
      fill="none"
      :stroke="cmpOption.itemStyle.actcolor"
      :stroke-width="valueStroke"
      :d="valueArc.d"
    />
    <line
      :x1="pointer.pos.x"
      :y1="pointer.pos.y"
      :x2="pointer.pos.x2"
      :y2="pointer.pos.y2"
      :stroke-width="cmpOption.pointer.stroke"
      :stroke="pointer.color"
    />
    <g v-for="item in ticks" :key="item.id">
      <line
        :x1="item.line.pos.x"
        :y1="item.line.pos.y"
        :x2="item.line.pos.x2"
        :y2="item.line.pos.y2"
        :stroke-width="item.line.stroke"
        :stroke="item.line.color"
      />
      <text
        :fill="item.text.color"
        :font-size="item.text.fontSize"
        :x="item.text.pos.x"
        :y="item.text.pos.y"
        text-anchor="middle"
        v-if="item.text.enabled"
      >
        {{ item.value }}
      </text>
    </g>
  </svg>
</template>

<script>
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 180) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var counterclockwise = endAngle < startAngle ? 1 : 0;
  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  if (counterclockwise) largeArcFlag = endAngle - startAngle > -180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    180,
    largeArcFlag,
    counterclockwise,
    end.x,
    end.y,
  ].join(" ");

  return {
    xmin: Math.min(start.x, end.x),
    ymin: Math.min(start.y, end.y),
    xmax: Math.max(start.x, end.x),
    ymax: Math.max(start.y, end.y),
    d: d,
  };
}

export default {
  name: "SetActGauge",

  props: {
    width: {
      type: String,
      default: "100%",
      required: false,
    },
    height: {
      type: String,
      default: "100%",
      required: false,
    },
    option: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    setpointvalue: {
      type: Number,
      required: false,
      default: 5,
    },
  },

  data: function () {
    return {
      //value: 20,
      //setpointvalue: 12,
      defOption: {
        startAngle: 30,
        endAngle: 150,
        radius: 100,
        min: 0,
        max: 20,
        unit: "unit",
        size: {
          offsetViewBoxWidth: 0,
          offsetViewBoxHeigth: 0,
        },
        itemStyle: {
          backcolor: "#BDBDBD",
          setcolor: "#4CAF50",
          actcolor: "#616161",
          stroke: 30,
          setStrokePercent: 20,
        },
        pointer: {
          stroke: 3,
          strokeColor: "#4CAF50",
        },
        axisTicks: {
          enabled: true,
          tickCount: 4,
          length: 5,
          stroke: 2,
          color: "#616161",
          split: {
            count: 2,
            length: 3,
            stroke: 2,
            color: "#616161",
          },
          axisLabel: {
            enabled: true,
            offset: 0,
            color: "#616161",
            fontSize: 10,
          },
        },
        text: {
          offsetX: 0,
          offsetY: -20,
          fontsize: 40,
          textcolor: "#616161",
          unittextcolor: "#616161",
        },
      },
    };
  },
  computed: {
        // a computed getter

        //Get options from props or from default
        cmpOption: function () {
        var returnVal = this.defOption;
        
        returnVal = Object.assign(returnVal, this.option)
        if (Object.prototype.hasOwnProperty.call(this.option, "size"))
            returnVal.size = Object.assign(returnVal.size, this.option.size)

        if (Object.prototype.hasOwnProperty.call(this.option, "itemStyle"))
            returnVal.itemStyle = Object.assign(returnVal.itemStyle, this.option.itemStyle)

        if (Object.prototype.hasOwnProperty.call(this.option, "pointer"))
            returnVal.pointer = Object.assign(returnVal.pointer, this.option.pointer)

        if (Object.prototype.hasOwnProperty.call(this.option, "text"))
            returnVal.text = Object.assign(returnVal.text, this.option.text)

        if (Object.prototype.hasOwnProperty.call(this.option, "axisTicks"))
            returnVal.axisTicks = Object.assign(returnVal.axisTicks, this.option.axisTicks)


        return returnVal;
    },

    viewbox: function () {
      var res = "0 0 " + this.svgwidth + " " + this.svgheigth;
      return res;
    },

    svgwidth: function () {
      var option = this.cmpOption;

      return this.centerPoint.x * 2 + option.size.offsetViewBoxWidth;
    },
    svgheigth: function () {
      var option = this.cmpOption;
      return this.centerPoint.y * 2 + option.size.offsetViewBoxHeigth;
    },
    //Get start end difference
    diffStartEndAngle: function () {
      return this.cmpOption.endAngle - this.cmpOption.startAngle;
    },
    //Get min max difference
    diffMinMaxValue: function () {
      return this.cmpOption.max - this.cmpOption.min;
    },
    //Get factor
    factor: function () {
      if (this.diffMinMaxValue === 0) return 1;

      return this.diffStartEndAngle / this.diffMinMaxValue;
    },
    //Get width and height
    centerPoint: function () {
      var option = this.cmpOption;
      var offsetTicks = 0;

      if (option.axisTicks.enabled) offsetTicks = option.axisTicks.length;
      if (option.axisTicks.axisLabel.enabled)
        offsetTicks += option.axisTicks.axisLabel.fontSize;

      var res = {
        x: option.radius + option.itemStyle.stroke + offsetTicks,
        y: option.radius + option.itemStyle.stroke + offsetTicks,
      };
      return res;
    },

    //Get text position
    textPosXYfunction() {
      var pos = this.centerPoint;
      return {
        x: pos.x + this.cmpOption.text.offsetX,
        y: pos.y + this.cmpOption.text.offsetY,
      };
    },

    outlineArc: function () {
      var option = this.cmpOption;
      return this.describeArc(
        option.radius,
        option.startAngle,
        option.endAngle
      );
    },
    setPointArc: function () {
      var option = this.cmpOption;
      var radius =
        option.radius + (option.itemStyle.stroke - this.setPointStroke) / 2;

      return this.describeArc(
        radius,
        option.startAngle,
        option.startAngle + Math.min(this.setpointvalue, this.cmpOption.max) * this.factor
      );
    },
    setPointStroke: function () {
      var strokeFactor = this.cmpOption.itemStyle.setStrokePercent / 100;
      return this.cmpOption.itemStyle.stroke * strokeFactor;
    },
    valueArc: function () {
      var radius =
        this.cmpOption.radius -
        (this.cmpOption.itemStyle.stroke - this.valueStroke) / 2;
      return this.describeArc(
        radius,
        this.cmpOption.startAngle,
        this.cmpOption.startAngle + Math.min(this.value, this.cmpOption.max) * this.factor
      );
    },

    pointer: function () {
      var option = this.cmpOption;
      var startRadius = option.radius - option.itemStyle.stroke / 2;
      var length = option.itemStyle.stroke;
      var angle = option.startAngle + Math.min(this.value, this.cmpOption.max) * this.factor;
      return {
        stroke: option.pointer.stroke,
        color:
          Math.max(this.value, this.cmpOption.min) === 0
            ? option.axisTicks.color
            : option.pointer.strokeColor,
        pos: this.getArcLine(startRadius, angle, length),
      };
    },

    ticks: function () {
      var res = [];
      var option = this.cmpOption;
      if (!option.axisTicks.enabled) return res;

      var startRadius = option.radius + option.itemStyle.stroke / 2 + 2;
      var dif = option.endAngle - option.startAngle;
      var tickCount =
        option.axisTicks.tickCount * (option.axisTicks.split.count + 1);
      var tickidx = tickCount / option.axisTicks.tickCount;
      var factor = dif / tickCount;
      var length;
      var textEnabled;
      var stroke;
      var linecolor;
      for (let i = 0; i <= tickCount; i++) {
        length = option.axisTicks.split.length;
        stroke = option.axisTicks.split.stroke;
        linecolor = option.axisTicks.split.color;
        textEnabled = false;
        if (i % tickidx === 0) {
          length = option.axisTicks.length;
          textEnabled = option.axisTicks.axisLabel.enabled;
          stroke = option.axisTicks.stroke;
          linecolor = option.axisTicks.color;
        }

        res[i] = {
          id: i,
          value: ((option.max - option.min) / tickCount) * i,
          text: {
            enabled: textEnabled,
            color: option.axisTicks.axisLabel.color,
            fontSize: option.axisTicks.axisLabel.fontSize,
            pos: this.polarToCartesian(
              startRadius + length + option.axisTicks.axisLabel.offset + 5,
              option.startAngle + factor * i
            ),
          },
          line: {
            stroke: stroke,
            color: linecolor,
            pos: this.getArcLine(
              startRadius,
              option.startAngle + factor * i,
              length
            ),
          },
        };
      }

      return res;
    },

    valueStroke: function () {
      return this.cmpOption.itemStyle.stroke - this.setPointStroke;
    },
  },

  // watch: {
  //   option: function(newVal, oldVal) { // watch it
  //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
  //   }
  // },

  methods: {
    cmpOptionSize: function (value) {
      var returnVal = this.defOption.size;
      if (Object.prototype.hasOwnProperty.call(value, "offsetViewBoxWidth"))
        returnVal.offsetViewBoxWidth = value.offsetViewBoxWidth;
      if (Object.prototype.hasOwnProperty.call(value, "offsetViewBoxHeigth"))
        returnVal.offsetViewBoxHeigth = value.offsetViewBoxHeigth;
      return returnVal;
    },
    cmpOptionItemStyle: function (value) {
      var returnVal = this.defOption.itemStyle;
      if (Object.prototype.hasOwnProperty.call(value, "backcolor"))
        returnVal.backcolor = value.backcolor;
      if (Object.prototype.hasOwnProperty.call(value, "setcolor"))
        returnVal.setcolor = value.setcolor;
      if (Object.prototype.hasOwnProperty.call(value, "actcolor"))
        returnVal.actcolor = value.actcolor;
      if (Object.prototype.hasOwnProperty.call(value, "stroke"))
        returnVal.stroke = value.stroke;
      if (Object.prototype.hasOwnProperty.call(value, "setStrokePercent"))
        returnVal.setStrokePercent = value.setStrokePercent;
      return returnVal;
    },
    cmpOptionPointer: function (value) {
      var returnVal = this.defOption.pointer;
      if (Object.prototype.hasOwnProperty.call(value, "stroke"))
        returnVal.stroke = value.pointer.stroke;
      if (Object.prototype.hasOwnProperty.call(value, "strokeColor"))
        returnVal.strokeColor = value.pointer.strokeColor;
      return returnVal;
    },
    cmpOptionText: function (value) {
      var returnVal = this.defOption.text;
      if (Object.prototype.hasOwnProperty.call(value, "offsetX"))
        returnVal.offsetX = value.offsetX;
      if (Object.prototype.hasOwnProperty.call(value, "offsetY"))
        returnVal.offsetY = value.offsetY;
      if (Object.prototype.hasOwnProperty.call(value, "fontsize"))
        returnVal.fontsize = value.fontsize;
      if (Object.prototype.hasOwnProperty.call(value, "textcolor"))
        returnVal.textcolor = value.textcolor;
      if (Object.prototype.hasOwnProperty.call(value, "unittextcolor"))
        returnVal.unittextcolor = value.unittextcolor;
      return returnVal;
    },

    cmpOptionAxisTicks: function (value) {
      var returnVal = this.defOption.axisTicks;
      if (Object.prototype.hasOwnProperty.call(value, "enabled"))
        returnVal.enabled = value.enabled;
      if (Object.prototype.hasOwnProperty.call(value, "tickCount"))
        returnVal.tickCount = value.tickCount;
      if (Object.prototype.hasOwnProperty.call(value, "stroke"))
        returnVal.stroke = value.stroke;
      if (Object.prototype.hasOwnProperty.call(value, "color"))
        returnVal.color = value.color;
      if (Object.prototype.hasOwnProperty.call(value, "split"))
        returnVal.split = this.cmpOptionAxisTicksSplit(value.split);
      if (Object.prototype.hasOwnProperty.call(value, "axisLabel"))
        returnVal.axisLabel = this.cmpOptionAxisTicksSplit(value.axisLabel);

      return returnVal;
    },
    cmpOptionAxisTicksSplit: function (value) {
      var returnVal = this.defOption.axisTicks.split;
      if (Object.prototype.hasOwnProperty.call(value, "count"))
        returnVal.count = value.count;
      if (Object.prototype.hasOwnProperty.call(value, "length"))
        returnVal.length = value.length;
      if (Object.prototype.hasOwnProperty.call(value, "stroke"))
        returnVal.stroke = value.stroke;
      if (Object.prototype.hasOwnProperty.call(value, "color"))
        returnVal.color = value.color;
      return returnVal;
    },
    cmpOptionAxisTicksAxisLable: function (value) {
      var returnVal = this.defOption.axisTicks.axisLabel;
      if (Object.prototype.hasOwnProperty.call(value, "enabled"))
        returnVal.enabled = value.enabled;
      if (Object.prototype.hasOwnProperty.call(value, "offset"))
        returnVal.offset = value.offset;
      if (Object.prototype.hasOwnProperty.call(value, "color"))
        returnVal.color = value.color;
      if (Object.prototype.hasOwnProperty.call(value, "fontSize"))
        returnVal.fontSize = value.fontSize;
      return returnVal;
    },

    getArcLine: function (radius, angle, length) {
      var pos1 = this.polarToCartesian(radius, angle);

      var pos2 = this.polarToCartesian(radius + length, angle);

      return {
        x: pos1.x,
        x2: pos2.x,
        y: pos1.y,
        y2: pos2.y,
      };
    },

    polarToCartesian: function (radius, angle) {
      return polarToCartesian(
        this.centerPoint.x,
        this.centerPoint.y,
        radius,
        angle
      );
    },

    describeArc: function (radius, startAngle, endAngle) {
      return describeArc(
        this.centerPoint.x,
        this.centerPoint.y,
        radius,
        startAngle,
        endAngle
      );
    },
    countDigits: function (value) {
      return (value + "").length;
    },
  },
};
</script>